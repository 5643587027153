import React, { FC } from "react";
import { Link, useLocation } from "react-router-dom";
import styles from "./styles.m.scss";

const Header: FC<{ black: boolean }> = ({ black }) => {
  const { pathname } = useLocation();
  
  return (
    <header className={`${styles.header} ${black ? styles.color : ""}`}>
      <nav className={styles.nav}>
        <Link 
          to="/search" 
          className={styles.link}
          style={{ animationDelay: '0.1s' }}
        >
          SEA_RCH
        </Link>
        <Link 
          to={pathname === "/sections" ? "/digest" : "/sections"}
          className={styles.link}
          style={{ animationDelay: '0.2s' }}
        >
          {pathname === "/sections" ? "DIG-est" : "Секции"}
        </Link>
      </nav>
    </header>
  );
};

export default Header;
