import React, { useEffect, useRef, useState } from 'react';
import p5 from 'p5';

import styles from './styles.m.scss';

const vertShader = `
  precision highp float;
  attribute vec3 aPosition;
  attribute vec2 aTexCoord;
  varying vec2 vTexCoord;

  void main() {
    vTexCoord = aTexCoord;
    vec4 positionVec4 = vec4(aPosition, 1.0);
    positionVec4.xy = positionVec4.xy * 2.0 - 1.0;
    gl_Position = positionVec4;
  }
`;

const fragShader = `
  precision highp float;
  varying vec2 vTexCoord;
  uniform vec2 u_resolution;
  uniform float u_time;
  uniform vec2 u_mouse;

  #define NUM_OCTAVES 5

  float random(vec2 st) {
    return fract(sin(dot(st.xy, vec2(12.9898,78.233))) * 43758.5453123);
  }

  float noise(vec2 st) {
    vec2 i = floor(st);
    vec2 f = fract(st);
    float a = random(i);
    float b = random(i + vec2(1.0, 0.0));
    float c = random(i + vec2(0.0, 1.0));
    float d = random(i + vec2(1.0, 1.0));
    vec2 u = f * f * (3.0 - 2.0 * f);
    return mix(a, b, u.x) + (c - a)* u.y * (1.0 - u.x) + (d - b) * u.x * u.y;
  }

  float fbm(vec2 st) {
    float value = 0.0;
    float amplitude = 0.5;
    float frequency = 0.0;
    for (int i = 0; i < NUM_OCTAVES; i++) {
      value += amplitude * noise(st);
      st *= 2.0;
      amplitude *= 0.5;
    }
    return value;
  }

  void main() {
    vec2 st = gl_FragCoord.xy/u_resolution.xy;
    st.x *= u_resolution.x/u_resolution.y;
    vec3 color = vec3(0.0);
    vec2 q = vec2(0.);
    q.x = fbm(st + 0.1*u_time);
    q.y = fbm(st + vec2(1.0));
    vec2 r = vec2(0.);
    r.x = fbm(st + 1.0*q + vec2(1.7,9.2)+ 0.15*u_time);
    r.y = fbm(st + 1.0*q + vec2(8.3,2.8)+ 0.126*u_time);
    float f = fbm(st+r);
    color = mix(
      vec3(0.101961,0.619608,0.666667),
      vec3(0.666667,0.666667,0.498039),
      clamp((f*f)*4.0,0.0,1.0)
    );
    color = mix(
      color,
      vec3(0,0,0.164706),
      clamp(length(q),0.0,1.0)
    );
    color = mix(
      color,
      vec3(0.666667,1,1),
      clamp(length(r.x),0.0,1.0)
    );
    gl_FragColor = vec4((f*f*f+.6*f*f+.5*f)*color,1.);
  }
`;

const Background = () => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [hasWebGLError, setHasWebGLError] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const checkMobile = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    
    checkMobile();
    window.addEventListener('resize', checkMobile);
    return () => window.removeEventListener('resize', checkMobile);
  }, []);

  useEffect(() => {
    if (!containerRef.current || isMobile) return;

    let canvas: p5.Renderer | null = null;
    let sketch: p5 | null = null;

    try {
      sketch = new p5((p: p5) => {
        let shader: p5.Shader;

        p.setup = () => {
          try {
            canvas = p.createCanvas(p.windowWidth, p.windowHeight, p.WEBGL);
            canvas.parent(containerRef.current!);
            shader = p.createShader(vertShader, fragShader);
            p.shader(shader);
            p.noStroke();
          } catch (error) {
            console.error('WebGL initialization error:', error);
            setHasWebGLError(true);
            if (canvas) {
              canvas.remove();
            }
          }
        };

        p.draw = () => {
          if (hasWebGLError) return;
          
          try {
            shader.setUniform('u_resolution', [p.width, p.height]);
            shader.setUniform('u_time', p.millis() / 1000.0);
            shader.setUniform('u_mouse', [p.mouseX / p.width, p.mouseY / p.height]);
            p.rect(0, 0, p.width, p.height);
          } catch (error) {
            console.error('WebGL render error:', error);
            setHasWebGLError(true);
            if (canvas) {
              canvas.remove();
            }
          }
        };

        p.windowResized = () => {
          if (!hasWebGLError) {
            p.resizeCanvas(p.windowWidth, p.windowHeight);
          }
        };
      });
    } catch (error) {
      console.error('p5 initialization error:', error);
      setHasWebGLError(true);
    }

    return () => {
      if (sketch) {
        sketch.remove();
      }
    };
  }, [isMobile, hasWebGLError]);

  return (
    <div 
      ref={containerRef} 
      className={`${styles.container} ${(hasWebGLError || isMobile) ? styles.fallback : ''}`}
    />
  );
};

export default Background;
