import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import classNames from "classnames";

import MyHelmet from "@root/components/MyHelmet/MyHelmet";
import PreLoader from "@root/components/PreLoader/PreLoader";
import Template from "@root/components/Template/Template";
import Background from "@root/components/Background/Background";

import styles from "./styles.m.scss";

const menuItems = [
  {
    title: "SEA_RCH",
    path: "/search",
    description: "The Search tab will help you to find the specific piece of writing, author, or literary collection.",
  },
  {
    title: "DIG-est",
    path: "/digest",
    description: "The Digest tab is a curated collection of the most recent and interesting news, articles, and reviews.",
  },
  {
    title: "Секции",
    path: "/sections",
    description: "The Sections tab contains digital versions of the magazine's issues.",
  },
];

const Main = () => {
  const [activeIndex, setActiveIndex] = useState<number | null>(null);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  
  return (
    <>
      <PreLoader />
      <Background />
      <MyHelmet
        title="ROSAMUNDI"
        description="rosamundi — поэтический проект, вдохновленный философией Д. Андреева."
        image="https://rosa-mundi.ru/og.jpg"
      />
      <Template backgroundImage="none" footer={true} header={true} headerColor={false}>
        <div className={styles.mainContainer}>
          <div className={styles.titleContainer}>
            <h1 className={styles.title}>ROSAMUNDI</h1>
            <div className={styles.subtitle}>поэтический проект</div>
          </div>
          
          <nav className={styles.navigation}>
            {menuItems.map((item, index) => (
              <div
                key={item.path}
                className={classNames(styles.menuItem, {
                  [styles.active]: activeIndex === index
                })}
                onMouseEnter={() => setActiveIndex(index)}
                onMouseLeave={() => setActiveIndex(null)}
              >
                <Link to={item.path} className={styles.menuLink}>
                  <span className={styles.menuTitle}>{item.title}</span>
                  <div className={styles.menuDescription}>
                    {item.description}
                  </div>
                </Link>
              </div>
            ))}
          </nav>
        </div>
      </Template>
    </>
  );
};

export default Main;
