import React, { ChangeEvent, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Loader from "../../components/Loader/Loader";
import MyHelmet from "../../components/MyHelmet/MyHelmet";
import PreLoader from "../../components/PreLoader/PreLoader";
import Template from "../../components/Template/Template";
import ToggleButton from "../../components/ToggleButton/ToggleButton";
import { SearchData, SearchDataObject } from "../../types/search";
import { fetchGetSearch } from "../../api";
import styles from "./styles.m.scss";

const MAX_PREVIEW_LENGTH = 200;

const stripHtml = (html: string) => {
  const tmp = document.createElement("DIV");
  tmp.innerHTML = html;
  return tmp.textContent || tmp.innerText || "";
};

const formatPreview = (content: string) => {
  const cleanText = stripHtml(content);
  if (cleanText.length <= MAX_PREVIEW_LENGTH) return cleanText;
  return cleanText.substring(0, MAX_PREVIEW_LENGTH).trim() + "...";
};

const getPreviewText = (item: SearchDataObject, type: "author" | "materials") => {
  if (type === "author") {
    const previewParts: string[] = [];
    if (item.years) previewParts.push(item.years);
    if (item.description) previewParts.push(formatPreview(item.description));
    return previewParts.filter(Boolean).join(" • ");
  }
  return item.content ? formatPreview(item.content) : "";
};

const Search = () => {
  const [searchValue, setSearchValue] = useState("");
  const [checked, setChecked] = useState<"author" | "materials">("author");
  const [data, setData] = useState<SearchData>([]);
  const [loader, setLoader] = useState(false);
  const [error, setError] = useState(false);

  const handleClickChecked = (value: "author" | "materials") => {
    setChecked(value);
    if (searchValue) {
      setLoader(true);
    }
  };

  const searchOnChange = (event: ChangeEvent<HTMLInputElement>) => {
    setLoader(true);
    setSearchValue(event.target.value);
    setError(false);
  };

  useEffect(() => {
    const timer = setTimeout(async () => {
      if (!searchValue) {
        setData([]);
        setLoader(false);
        return;
      }

      try {
        const response = await fetchGetSearch(checked === "author" ? 0 : 1, searchValue);
        if (Array.isArray(response)) {
          setData(response);
        } else {
          setData([]);
        }
      } catch (error) {
        console.error("Search error:", error);
        setData([]);
        setError(true);
      } finally {
        setLoader(false);
      }
    }, 500);

    return () => clearTimeout(timer);
  }, [searchValue, checked]);

  return (
    <>
      <PreLoader />
      <MyHelmet
        description="Ищите требующийся материал или любимого автора в разделе поиска rosamundi."
        image="https://rosa-mundi.ru/og.jpg"
        title="ROSAMUNDI | Поиск"
      />
      <Template backgroundImage="author" footer={true} headerColor={false} header={true}>
        <div className={styles.titleContainer}>
          <Link to="/">
            <h1 className={styles.title}>ROSAMUNDI</h1>
          </Link>
          <div className={styles.subtitle}>SEA_RCH</div>
        </div>
        <div className={styles.searchModule}>
          <div className={styles.searchToggle}>
            <ToggleButton 
              checked={checked === "author"} 
              id="author" 
              cb={() => handleClickChecked("author")} 
            />
            <div className={styles.searchVariants}>
              {checked === "author" ? "Поиск по авторам" : "Поиск по материалам"}
            </div>
            <ToggleButton
              checked={checked === "materials"}
              id="materials"
              cb={() => handleClickChecked("materials")}
            />
          </div>
          <div className={styles.searchInput}>
            <input
              placeholder={`Введите ${checked === "author" ? "имя автора" : "название материала"}...`}
              onChange={searchOnChange}
              value={searchValue}
              onFocus={(e) => (e.target.placeholder = "")}
              onBlur={(e) => 
                (e.target.placeholder = `Введите ${checked === "author" ? "имя автора" : "название материала"}...`)
              }
            />
            <div className={styles.searchIcon}>→</div>
          </div>
          {error && (
            <div className={styles.errorMessage}>
              Произошла ошибка при поиске. Пожалуйста, попробуйте позже.
            </div>
          )}
        </div>
        {(loader || !!searchValue) && (
          <div className={styles.resultsContainer}>
            {loader ? (
              <Loader />
            ) : data.length > 0 ? (
              <div className={styles.resultsList}>
                {data.map((item) => (
                  <Link 
                    key={item.id} 
                    to={`/${item.type}/${item.slug}`} 
                    className={styles.resultItem}
                  >
                    <h3 className={styles.resultTitle}>{item.name}</h3>
                    <p className={styles.resultContent}>
                      {getPreviewText(item, checked)}
                    </p>
                  </Link>
                ))}
              </div>
            ) : searchValue && (
              <div className={styles.noResults}>
                По вашему запросу ничего не найдено
              </div>
            )}
          </div>
        )}
      </Template>
    </>
  );
};

export default Search;
