import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import NotFound from "../NotFound/NotFound";
import { fetchGetSections } from "@root/api";
import Loader from "@root/components/Loader/Loader";
import MyHelmet from "@root/components/MyHelmet/MyHelmet";
import PreLoader from "@root/components/PreLoader/PreLoader";
import Template from "@root/components/Template/Template";
import { SectionPaginatedData } from "@root/types/section";

import styles from "./styles.m.scss";

const Sections = () => {
  const [data, setData] = useState<SectionPaginatedData | "init" | "error">("init");
  const [loader, setLoader] = useState(true);

  useEffect(() => {
    window.scrollTo(0, 0);
    const timer = setTimeout(() => {
      setLoader(false);
    }, 1000);
    (async () => {
      try {
        const response = await fetchGetSections(1, 100);
        setData(response);
      } catch (error) {
        setData("error");
      }
    })();
    return () => clearTimeout(timer);
  }, []);

  const formatNumber = (index: number, total: number) => {
    return (total - index).toString().padStart(2, '0');
  };

  return (
    <>
      <PreLoader backgroundColorWhite={false} />
      {data === "error" && <NotFound />}
      {data !== "error" && data !== "init" && (
        <>
          <MyHelmet
            description="В разделе секций мы подобрали для вас материалы, вошедшие в цифровые копии журнала rosamundi."
            image="https://rosa-mundi.ru/og.jpg"
            title="ROSAMUNDI | Секции"
          />
          <Template backgroundImage="sections" header={true} footer={true} headerColor={false}>
            <Link to="/" className={styles.title}>
              ROSAMUNDI
            </Link>
            {loader ? (
              <Loader />
            ) : (
              <>
                {data.size === 0 ? (
                  <div className={styles.noSections}>Секции пока не добавлены</div>
                ) : (
                  <div className={styles.sectionsContainer}>
                    {data.sections.map((section, index) => (
                      <div key={section.id} className={styles.section}>
                        <div className={styles.sectionItem}>
                          <div className={styles.sectionNumber}>
                            {formatNumber(index, data.size)}
                          </div>
                          <Link
                            to={`/section/${section.slug}`}
                            className={styles.sectionLink}
                            state={{ type: "section" }}
                          >
                            {section.title || "Без названия"}
                          </Link>
                          <div className={styles.sectionArrow}>→</div>
                        </div>
                        {section.description && (
                          <div className={styles.sectionDescription}>
                            {section.description}
                          </div>
                        )}
                      </div>
                    ))}
                  </div>
                )}
              </>
            )}
          </Template>
        </>
      )}
    </>
  );
};

export default Sections;
