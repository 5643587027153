import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import styles from "./styles.m.scss";

const FOOTER_CONTENT = [
  {
    title: "GMAIL",
    href: "mailto:rosa.mundi.redaction@gmail.com",
    hideOnMobile: false,
    order: 1
  },
  {
    title: "ROSAMUNDI",
    href: "/",
    hideOnMobile: false,
    order: 2
  },
  {
    title: "VK",
    href: "https://vk.com/rosamundi",
    hideOnMobile: false,
    order: 3
  },
  {
    title: "TELEGRAM",
    mobileTitle: "TG",
    href: "https://t.me/rosa_mundi_tg",
    hideOnMobile: false,
    order: 4
  },
  {
    title: "YOUTUBE",
    href: "https://www.youtube.com/@ROSA_MUNDI_REDACTION",
    hideOnMobile: true,
    order: 5
  }
];

const Footer = () => {
  const location = useLocation();
  const isLightPage = ['/about'].includes(location.pathname);
  const [isVisible, setIsVisible] = useState(true);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const checkMobile = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    
    checkMobile();
    window.addEventListener('resize', checkMobile);
    
    return () => window.removeEventListener('resize', checkMobile);
  }, []);

  const visibleContent = FOOTER_CONTENT
    .filter(item => !isMobile || !item.hideOnMobile)
    .sort((a, b) => a.order - b.order);

  return (
    <div className={styles.footerWrapper}>
      <button 
        className={`${styles.toggleButton} ${!isVisible ? styles.hidden : ''}`}
        onClick={() => setIsVisible(!isVisible)}
        aria-label={isVisible ? "Hide footer" : "Show footer"}
      >
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M7 14l5-5 5 5" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
      </button>
      <footer className={`${styles.footer} ${isLightPage ? styles.lightPage : ''} ${!isVisible ? styles.hidden : ''}`}>
        {visibleContent.map((item) => (
          <Link
            key={item.title}
            to={item.href}
            className={styles.link}
            target={item.href.startsWith("http") ? "_blank" : undefined}
            rel={item.href.startsWith("http") ? "noopener noreferrer" : undefined}
          >
            {isMobile && item.mobileTitle ? item.mobileTitle : item.title}
          </Link>
        ))}
      </footer>
    </div>
  );
};

export default Footer;
