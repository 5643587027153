import React, { useEffect, useRef } from 'react';
import styles from './styles.m.scss';

const Cursor: React.FC = () => {
  const cursorOuterRef = useRef<HTMLDivElement>(null);
  const cursorInnerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const cursorOuter = cursorOuterRef.current;
    const cursorInner = cursorInnerRef.current;
    if (!cursorOuter || !cursorInner) return;

    let mouseX = window.innerWidth / 2;
    let mouseY = window.innerHeight / 2;
    let outterX = window.innerWidth / 2;
    let outterY = window.innerHeight / 2;
    let innerX = window.innerWidth / 2;
    let innerY = window.innerHeight / 2;

    const animate = () => {
      outterX += (mouseX - outterX) * 0.2;
      outterY += (mouseY - outterY) * 0.2;
      innerX += (mouseX - innerX) * 0.4;
      innerY += (mouseY - innerY) * 0.4;

      cursorOuter.style.transform = `translate(${outterX - cursorOuter.offsetWidth / 2}px, ${outterY - cursorOuter.offsetHeight / 2}px)`;
      cursorInner.style.transform = `translate(${innerX - cursorInner.offsetWidth / 2}px, ${innerY - cursorInner.offsetHeight / 2}px)`;
      requestAnimationFrame(animate);
    };

    const onMouseMove = (e: MouseEvent) => {
      mouseX = e.clientX;
      mouseY = e.clientY;
    };

    const onMouseOver = (e: MouseEvent) => {
      const target = e.target as HTMLElement;
      if (target.tagName === 'A' || target.tagName === 'BUTTON' || target.closest('button') || target.closest('a')) {
        cursorOuter.classList.add(styles.hover);
        cursorInner.classList.add(styles.hover);
      }
    };

    const onMouseOut = () => {
      cursorOuter.classList.remove(styles.hover);
      cursorInner.classList.remove(styles.hover);
    };

    document.addEventListener('mousemove', onMouseMove);
    document.addEventListener('mouseover', onMouseOver);
    document.addEventListener('mouseout', onMouseOut);
    animate();

    return () => {
      document.removeEventListener('mousemove', onMouseMove);
      document.removeEventListener('mouseover', onMouseOver);
      document.removeEventListener('mouseout', onMouseOut);
    };
  }, []);

  return (
    <>
      <div ref={cursorOuterRef} className={styles.cursorOuter} />
      <div ref={cursorInnerRef} className={styles.cursorInner} />
    </>
  );
};

export default Cursor;
